.energiya-page {
  .sidebar-link {
    &.active:not(.disabled),
    &:hover:not(.disabled) {
      background-color: $primaryYellow;
    }
  }
}
.sidebar {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 300px;
  height: calc(100vh - 85px);
  padding: 54px 0 96px;
  overflow: auto;

  &__nav {
    padding-right: 20px;
    //margin: 0 -30px 50px -30px;
  }

  &__organization {
    margin-bottom: 50px;
    padding-bottom: 15px;
    border-bottom: 1px solid $border;
    text-align: center;
    font-weight: 300;
    font-size: 16px;
    line-height: 15px;
    color: $text;
  }

  &-link {
    display: flex;
    align-items: center;
    height: 48px;
    margin-left: -20px;
    margin-bottom: 20px;
    padding-left: 80px;
    font-size: 18px;
    letter-spacing: -0.03em;
    color: $black;
    transition: background-color 0.3s, color 0.3s;
    border-bottom-right-radius: 10px;
    transform: skew(-40deg);

    & > * {
      transform: skew(40deg);
    }

    &.active:not(.disabled),
    &:hover:not(.disabled) {
      background-color: $primaryBlue;
      color: var(--textBlackToWhite);
    }

    &.disabled {
      opacity: 0.3;
      cursor: not-allowed;

      &:hover {
        color: $text;
      }
    }

    &__box {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      margin-right: 12px;
    }
  }

  &-support {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: auto;
    padding-top: 70px;

    &__text {
      font-size: 18px;
      color: $text;
      font-variant: small-caps;
    }

    &-button {
      display: flex;
      align-items: center;
      margin-top: 28px;
      padding: 0;
      border: 1px solid $border;
      text-align: left;
      background-color: transparent;
      border-radius: 14px 0 14px 0;
      //cursor: pointer;

      &__box {
        width: 56px;
        height: 56px;
        border-right: 1px solid $border;
        border-radius: 14px 0 14px 0;
        overflow: hidden;
      }

      &__photo {
        width: auto;
        height: 100%;
      }

      &__inner {
        padding: 8px 24px 8px 14px;
      }

      &__name {
        font-size: 16px;
        letter-spacing: -0.03em;
        line-height: 16px;
        color: $text;
      }

      &__phone {
        margin-top: 8px;
        font-size: 16px;
        letter-spacing: -0.02em;
        line-height: 16px;
        color: $text;
      }

      &__text {
        font-size: 16px;
        letter-spacing: -0.02em;
        line-height: 16px;
        color: $text;

        + .sidebar-support-button__text {
          margin-top: 8px;
        }
      }
    }
  }
}
.sidebar-profile-container {
  border-right: 1px solid #d8dedc;
}

.sidebar-profile {
  width: 302px;
  height: 365px;
  padding: 14px;

  .sidebar-back-to {
    display: flex;
    align-items: center;
    width: 100%;
    height: 56px;
    border: 0;
    cursor: pointer;
    outline: 0;
    margin-top: 34px;
    padding: 0 16px;
    font-weight: normal;
    font-size: 16px;
    line-height: 146.5%;
    color: rgba(23, 24, 59, 0.5);
    background-color: transparent;

    img {
      margin-right: 12px;
    }
  }

  .sidebar-profile-tab {
    margin-top: 28px;
  }
}
