.documents {
  //width: 1000px;
  @include tablet() {
    overflow-x: hidden;
  }

  &.documents-b2c {
    .tab-label {
      min-width: 50%;
    }
  }
  .documents-tab-content {
    padding: 28px 34px;
    margin-top: 42px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;

    @include tablet() {
      margin-top: 20px;
      padding: 20px;
    }

    &.loader {
      min-height: 300px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .tab-label {
    min-width: 200px;
  }

  .btn.primary {
    padding: 12px 55px;
    height: 38px;
    font-weight: normal;
    font-size: 14px;
  }

  .download-receipt {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    img {
      width: 14px;
      height: 16px;
    }
    span {
      margin-left: 8px;
      font-weight: normal;
      font-size: 14px;
      line-height: 96%;
      letter-spacing: -0.01em;
      color: $black;
    }
  }

  .documents-contract {
    .info-green-box {
      margin-bottom: 34px;
    }
    .download-receipt {
      background-color: #e1ecf6;
      position: relative;
      right: -26px;
      width: 74px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      @include mobile() {
        position: absolute;
        right: 24px;
        top: 50%;
        bottom: 0;
        width: 24px;
        height: 24px;
        background-color: transparent;
        transform: translateY(-50%);
      }

      img {
        width: 24px;
        height: 24px;
      }
    }
  }
  .documents-contract-list {
    .info-green-box {
      margin-bottom: 34px;
    }
  }

  .contract-item-info {
    display: flex;
    flex-direction: column;

    .contract-item-title {
      font-weight: 500;
      font-size: 18px;
      line-height: 96%;
      color: $black;
      &:hover {
        cursor: pointer;
        color: $primaryBlue;
      }
    }

    .contract-item-sub-title {
      margin-top: 12px;
      font-size: 16px;
      line-height: 100%;
      color: $primaryGrey;
      font-weight: 300;

      @include mobile() {
        margin-top: 6px;
      }
    }
  }

  .custom-ant-table {
    width: 100%;
  }

  .scroll-wrap {
    overflow-x: auto;
    @include tablet() {
      margin: 0 -20px;
    }
  }

  &--energy {
    .custom-ant-table {
      th.ant-table-cell {
        background-color: $green;
      }
    }
  }
}

.custom-ant-table {
  width: max-content;
  min-width: 932px;
  .ant-table-thead > tr > th {
    height: 56px;
    padding: 8px;
  }
  .ant-table-tbody > tr > td {
    padding: 8px;
  }
  .status-label {
    margin: 0 auto;
  }
  .btn {
    margin: 0 auto;
  }
  .status-label.gray-blue span {
    font-size: 12px;
  }
}
.documents-receipt {
  .type {
    width: 122px;
  }
  .number {
    width: 90px;
  }
  .contract {
    width: 72px;
  }
  .date {
    width: 88px;
  }
  .volume {
    width: 84px;
  }
  .payment-amount {
    width: 98px;
  }
  .payment-status {
    width: 192px;
  }
  .receipt {
    width: 180px;
  }
}
.documents-receipt-gas {
  .number {
    width: 110px;
  }
  .contract {
    width: 84px;
  }
  .date,
  .volume {
    width: 100px;
  }
  .payment-amount {
    width: 136px;
  }
  .payment-status {
    width: 195px;
  }
  .receipt {
    width: 200px;
  }
}

.documents-bill {
  .acts {
    width: 250px;
  }
  .date {
    width: 150px;
  }
  .contract {
    width: 150px;
  }
  .volume {
    width: 150px;
  }
  .sign {
    width: 230px;
  }
}

.additional-agreement {
  .contract,
  .number,
  .date,
  .volume {
    width: 170px;
  }
  .sign {
    width: 248px;
  }
}

.documents-receipt-b2c {
  .number {
    width: 102px;
  }
  .address {
    width: 218px;
  }
  .ant-table-tbody > tr > td.address {
    text-align: left;
  }
  .payment-amount {
    width: 106px;
  }
  .payment-status {
    width: 192px;
  }
  .period {
    width: 130px;
  }
  .receipt {
    width: 182px;
  }
}

.documents-signature {
  .documents-signature-header {
    display: flex;
    align-items: center;
    padding: 6px 0 34px 0;

    .underline-link {
      margin: 0 20px 0 62px;
    }

    .documents-signature-header__title {
      max-width: 250px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-weight: 600;
      font-size: 24px;
      line-height: 102%;
      color: $black;
    }

    @include tablet() {
      flex-direction: column;
    }
  }

  .documents-signature-header__buttons {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: -16px;
    gap: 10px;

    @include tablet() {
      margin: 0;
      padding-top: 20px;
      flex-direction: column;
    }

    .btn {
      height: 38px;
      margin: 0 16px;
      padding: 0 30px;
    }

    .documents-signature-header__status {
      display: flex;
      align-items: center;
      border-radius: 20px;
      margin: 0 11px;
      padding: 5px 34px 5px 16px;
      background-color: $secondaryBlue;

      &.gas {
        background-color: $secondaryBlue;
      }

      &.energiya {
        background-color: #fff8e5;
      }

      svg {
        margin-right: 15px;
      }
    }

    &-sign {
      display: flex;

      @include tablet() {
        flex-direction: column;
        gap: 10px;
      }
    }
  }

  .documents-signature__iframe {
    margin: 0 -34px -36px -34px;
  }
}

.documents-tab-content {
  position: relative;

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    & > div {
      width: 100%;
    }
    .ant-spin {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 250px;
    }
  }

  &.selected {
    margin-top: 50px;
  }
}

.energiya-page {
  .tab-label {
    &.secondary {
      &.active {
        background-color: $primaryYellow;
      }
    }
    &.primary {
      &.active {
        color: $black;
        &::after {
          background-color: $green;
        }
      }
    }
  }
}

.tab-label {
  &.primary {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 56px;
    width: 50%;
    font-weight: 300;
    font-size: 16px;
    line-height: 96%;
    letter-spacing: 0.01em;
    color: $black;
    cursor: pointer;
    z-index: 2;

    &.active {
      color: $primaryBlue;
      font-weight: 500;
      border: none;

      &:after {
        content: "";
        width: 100%;
        height: 4px;
        position: absolute;
        bottom: 0;
        left: 0;
        background: $primaryBlue;
      }
    }
  }

  &.secondary {
    position: relative;
    min-width: 180px;
    height: 38px;
    color: transparent;
    border-radius: 10px 0 10px 0;
    cursor: pointer;

    @include tablet() {
      min-width: 145px;
    }

    .tab-label__text {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      transform: skew(40deg);
      font-weight: 300;
      font-size: 14px;
      color: $black;

      @include tablet() {
        font-size: 12px;
      }
    }

    &.active {
      background-color: $primaryBlue;

      .tab-label__text {
        font-weight: 500;
        color: var(--textBlackToWhite);
      }
    }
  }

  &.disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
}

.tab-label-list {
  @include tablet() {
    width: 100%;
    margin-bottom: 20px;
    border-bottom: 0;
    overflow-x: auto;
  }

  .tab-label {
    @include tablet() {
      min-width: 25%;
      width: auto;
      height: 30px;
      padding: 0 10px 12px;
      flex-shrink: 0;
    }
  }
}

.tab-label-list:not(.skew) {
  display: flex;
  margin-bottom: 30px;
  border-bottom: 1px solid #d8dedc;

  @include mobile() {
    margin-bottom: 20px;
  }
}

.tab-label-list.skew {
  display: flex;
  align-items: center;
  transform: skew(-40deg);
  background-color: #f7fbff;
  border-radius: 10px 0 10px 0;

  @include mobile() {
    margin-top: 20px;
    width: 100%;
  }

  .tab-label {
    &__text {
      @include mobile() {
        font-size: 10px;
      }
    }
    &.secondary {
      @include mobile() {
        min-width: 104px;
        width: 33%;
        height: 26px;
      }
    }
  }
}

.signature-buttons {
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 30px 0;

  @include mobile() {
    padding: 0 0 10px 0;
  }
}

.signing-wrap {
  @include mobile() {
    display: flex;
    flex-direction: column;
  }
}
